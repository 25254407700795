// Calculting nearest canco location distance
export const nearestLocationsHandler = (userLocation, storeLocation) => {
  const minDistance = 40;
  const lon1 = (userLocation.lng * Math.PI) / 180;
  const lon2 = (storeLocation.lng * Math.PI) / 180;
  const lat1 = (userLocation.lat * Math.PI) / 180;
  const lat2 = (storeLocation.lat * Math.PI) / 180;

  // Haversine formula
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in miles. Use 3956
  // for km
  let r = 6371;

  let d = c * r;

  return {
    position: d < minDistance ? storeLocation : null,
    distance: parseInt(d),
  };
};

// Amenities search filter handler
export const amenitiesFilterHandler = (data, filterArray) => {
  return (
    data &&
    data.filter((node) => {
      if (filterArray.id.length === 0 && filterArray.province === "") {
        return data;
      } else if (filterArray.id.length === 0) {
        const provinceFilter =
          node.city?.name.toLowerCase() === filterArray.province.toLowerCase();

        return provinceFilter;
      } else if (filterArray.province === "") {
        const filterAmenities =
          filterArray.id.length > 0
            ? filterArray.id.every((filterTag) =>
                node.amenities.map((tag) => tag.id).includes(filterTag)
              )
            : false;

        return filterAmenities;
      } else {
        const filterAmenitiesCity =
          filterArray.id.length > 0
            ? filterArray.id.every(
                (filterTag) =>
                  node.amenities.map((tag) => tag.id).includes(filterTag) &&
                  node.city.name.toLowerCase() ===
                    filterArray.province.toLowerCase()
              )
            : false;
        return filterAmenitiesCity;
      }
    })
  );
};

// Table search filter handler
export const tableSearchFilterHandler = (data, filterArray) => {
  // console.log("loc dat", data);
  return (
    data &&
    data.filter((node) => {
      return filterArray === "" || filterArray === null
        ? data
        : node.name.toLowerCase().includes(filterArray.toLowerCase()) ||
            node.address.toLowerCase().includes(filterArray.toLowerCase());
    })
  );
};

// Format input in canada phone number format
export const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

// Helper function to parse errors
export const parseErrorsWithFieldNames = (errors) => {
  const messages = [];

  Object.entries(errors).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // Field-specific errors (e.g., { username: ["This field is required."] })
      value.forEach((message) => {
        messages.push({ field: key, message });
      });
    } else if (typeof value === 'object' && value !== null) {
      // Nested errors (e.g., { user: { email: ["Invalid email"] } })
      const nestedMessages = parseErrorsWithFieldNames(value);
      nestedMessages.forEach((nestedError) => {
        messages.push({
          field: `${key}.${nestedError?.field}`,
          message: nestedError?.message,
        });
      });
    } else if (typeof value === 'string') {
      // String errors (less common)
      messages.push({ field: key, message: value });
    }
  });

  return messages;
};

// Common error handler function
export const handleApiErrors = (error) => {
  let errorMessages = [{ field: null, message: 'An unknown error occurred.' }];

  if (error.response) {
    // Error response from the server
    const { status, data } = error.response;

    if (data) {
      if (data?.detail || data?.message) {
        // Handles { detail: "Error message" }
        if (Array.isArray(data?.message)) {
          errorMessages = data.message.map((message) => ({
            field: null,
            message,
          }));
        } else if (Array.isArray(data?.detail)) {
          errorMessages = data.detail.map((message) => ({
            field: null,
            message,
          }));
        } else {
          errorMessages = [{ field: null, message: data?.detail || data?.message }];
        }
      } else if (data?.non_field_errors && Array.isArray(data?.non_field_errors)) {
        errorMessages = data.non_field_errors.map((message) => ({
          field: null,
          message,
        }));
      } else if (Array.isArray(data)) {
        errorMessages = data.map((message) => ({ field: null, message }));
      } else if (typeof data === 'object' && data !== null) {
        errorMessages = parseErrorsWithFieldNames(data);
      }
    } else {
      let message;
      if (status === 403) {
        message = 'Forbidden: Access is denied.';
      } else if (status === 500) {
        message = 'Internal Server Error. Please try again later.';
      } else {
        message = `Unexpected error occurred. Status code: ${status}`;
      }
      errorMessages = [{ field: null, message }];
    }
  } else if (error?.request) {
    // Network errors or no response received
    errorMessages = [
      {
        field: null,
        message: 'No response from the server. Please check your connection.',
      },
    ];
  } else if (error?.message) {
    // JavaScript Error object
    errorMessages = [{ field: null, message: error?.message }];
  } else if (typeof error === 'string') {
    // Non-object errors
    errorMessages = [{ field: null, message: error }];
  }

  return errorMessages;
};
