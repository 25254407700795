import React, { useState } from "react";
import { routes } from "../../utils/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { Button, ErrorText } from "../../components/styledComponent";
import { resetPasswordInitialValues } from "../../utils/formik/initialvalue";
import { resetPasswordValidationSchema } from "../../utils/formik/validationSchema";
import styles from "./style.module.scss";
// import { newPasswordApiHandler } from "../../api/commonApiCall";
import { SecondaryLoader } from "../../components/ui";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import axios from "axios";
import { BASE_URL, POST_API_URLS } from "../../utils/apiConstant";
import { handleApiErrors } from "../../utils/helpers";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: resetPasswordInitialValues,
    validationSchema: resetPasswordValidationSchema,
    onSubmit: (values) => {
      const data = {
        token: queryParameters.get("token"),
        password: values.password,
      };
      newPasswordHandler(data);
    },
  });

  const newPasswordHandler = async (data) => {
    setIsLoading(true);
    try {
      const apiResponse = await axios.post(
        `${BASE_URL + POST_API_URLS.NEW_PASSWORD}`,
        data
      );
      toast.success("Password Updated!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: { fontSize: "1.6rem", fontWeight: 500 },
      });
      setTimeout(() => navigate(routes.LOGIN), 1500);
      setIsLoading(false);
      return apiResponse?.data;
    } catch (error) {
      setIsLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage, {
          position: "bottom-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          style: { fontSize: "1.6rem", fontWeight: 500 },
        });
      });
    }
    // setIsLoading(true);
    // const res = await newPasswordApiHandler(data);
    // // console.log("new pass", res);
    // res &&
    //   res.error &&
    //   toast.error("Password Update Failed!", {
    //     position: "bottom-center",
    //     autoClose: 1500,
    //     hideProgressBar: false,
    //     closeOnClick: false,
    //     pauseOnHover: false,
    //     draggable: false,
    //     progress: undefined,
    //     theme: "colored",
    //     style: { fontSize: "1.6rem", fontWeight: 500 },
    //   });
    // res &&
    //   res.response &&
    //   toast.success("Password Updated!", {
    //     position: "bottom-center",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //     style: { fontSize: "1.6rem", fontWeight: 500 },
    //   });
    // res && res.response && setTimeout(() => navigate(routes.LOGIN), 1500);
    // setIsLoading(false);
  };

  return (
    <div className={styles.resetPasswordPage}>
      <div className={styles.resetPasswordBox}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <label htmlFor="newPassword">New Password</label>
            <div className={styles.passwordInputContainer}>
              <input
                type={showNewPassword ? "text" : "password"}
                name="newPassword"
                {...formik.getFieldProps("password")}
                style={
                  formik.touched.password &&
                  formik.errors.password && { boxShadow: "0px 0px 0px 2px red" }
                }
              />
              <span
                onClick={() => {
                  if (showNewPassword) {
                    setShowNewPassword(false);
                  } else {
                    setShowNewPassword(true);
                  }
                }}
              >
                {showNewPassword ? <EyeSlash size={25} /> : <Eye size={25} />}
              </span>
            </div>
            {formik.touched.password && formik.errors.password && (
              <ErrorText className={styles.errorText}>
                {formik.errors.password}
              </ErrorText>
            )}
          </div>
          <div>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <div className={styles.passwordInputContainer}>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                {...formik.getFieldProps("confirmPassword")}
                style={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              <span
                onClick={() => {
                  if (showConfirmPassword) {
                    setShowConfirmPassword(false);
                  } else {
                    setShowConfirmPassword(true);
                  }
                }}
              >
                {showConfirmPassword ? (
                  <EyeSlash size={25} />
                ) : (
                  <Eye size={25} />
                )}
              </span>
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.confirmPassword}
                </ErrorText>
              )}
          </div>
          <Button type="submit">
            {isLoading ? <SecondaryLoader /> : "Submit"}
          </Button>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default ResetPassword;
